<template>
    <v-row no-gutters :class="wide ? 'full-width-row' : ''">
        <v-col cols="12" sm="12" :md="wide ? 2 : 4">
            <label class="f-label">{{$t(label)}}</label>
        </v-col>
        <v-col  cols="12" sm="12" :md="wide ? 10 : 8">
            
            <div v-if="$slots.view" :class="[multi ? 'more-per-lab-wrap' : '', 'onlyReadData']">
                <slot name="view"></slot>            
            </div>

            <slot name="edit"></slot>            
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "Field",
    props: {
        label: {
            type: String,
            default: null
        },
        wide: {
            type: Boolean,
            default: false
        },
        multi: {
            type: Boolean,
            default: false
        }
    }       
};
</script>

